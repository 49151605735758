import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'

const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
      }
    }
  }
`

const Seo = ({ description, keywords, title, url, author }) => {
  const { origin } = useLocation()

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const sm = data.site.siteMetadata

        const metaDescription = description || sm.description
        const metaTitle = title || sm.title
        const metaAuthor = author || sm.author
        const metaUrl = url || origin
        const metaImage = `${origin}/og-image.png`
        const metaKeywords = keywords || ['Blog', 'Code', 'Applescript', 'Bash', 'Mac']

        if (process.env.NODE_ENV === 'development') {
          const seoObject = {
            Title: metaTitle,
            Description: metaDescription,
            Author: metaAuthor,
            URL: metaUrl,
            Image: metaImage,
            Keywords: metaKeywords,
          }
          console.log('SEO', seoObject)
        }

        return (
          <Helmet
            title={`${title} | ${sm.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                property: `twitter:creator`,
                content: metaAuthor,
              },
              {
                property: `twitter:title`,
                content: metaTitle,
              },
              {
                property: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `twitter:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : [],
            )}
          ></Helmet>
        )
      }}
    />
  )
}

export default Seo
